import { Trans } from 'react-i18next';
import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import ErrorIcon from './img/error.png';

export const ErrorInstallAppPage = () => {
    return (
        <S.FailedContainer>
            <S.FailedIcon src={ErrorIcon} alt="error icon" />
            <Text type="h5" mb={12} center>
                <Trans i18nKey="installAppError.title" />
            </Text>
            <Text mb={32} type="medium-text" color="text-secondary" center>
                <Trans i18nKey="installAppError.subTitle" components={[<u key="0" />]} />
            </Text>
            <S.ContactLink href="mailto:support@walk-fit.io?subject=Pending failed">
                <Trans i18nKey="basics.contactSupport" />
            </S.ContactLink>
        </S.FailedContainer>
    );
};
