export default {
    ios: {
        en: 'https://support.walk-fit.io/hc/en-us/categories/12147177915165-IOS',
        es: 'https://support.walk-fit.io/hc/es/categories/12147177915165-IOS',
        pt: 'https://support.walk-fit.io/hc/pt/categories/12147177915165-IOS',
        it: 'https://support.walk-fit.io/hc/it/categories/12147177915165-IOS',
        de: 'https://support.walk-fit.io/hc/de/categories/12147177915165-IOS',
        fr: 'https://support.walk-fit.io/hc/fr/categories/12147177915165-IOS',
    },
    android: {
        en: 'https://support.walk-fit.io/hc/en-us/categories/12147583387933-Android',
        es: 'https://support.walk-fit.io/hc/es/categories/12147583387933-Android',
        pt: 'https://support.walk-fit.io/hc/pt/categories/12147583387933-Android',
        it: 'https://support.walk-fit.io/hc/it/categories/12147583387933-Android',
        de: 'https://support.walk-fit.io/hc/de/categories/12147583387933-Android',
        fr: 'https://support.walk-fit.io/hc/fr/categories/12147583387933-Android',
    },
    desktop: {
        en: 'https://support.walk-fit.io/hc/en-us',
        es: 'https://support.walk-fit.io/hc/es',
        pt: 'https://support.walk-fit.io/hc/pt',
        it: 'https://support.walk-fit.io/hc/it',
        de: 'https://support.walk-fit.io/hc/de',
        fr: 'https://support.walk-fit.io/hc/fr',
    },
};
