import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { Text } from 'components/library';
import { Alert } from 'components/Icon';

import * as S from './styled';

import { ISubscriptionRedemption } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';
import CancelButton from './CancelButton';

const SubscriptionRedemption = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    trackCardExpand,
    price,
    onCancelSubscription,
    subscriptionType,
}: ISubscriptionRedemption) => {
    const { t } = useTranslation();
    const [isTabExpanded, setTabExpanded] = useState<boolean>(false);

    const handleShowMore = () => {
        const newExpandedState = !isTabExpanded;

        setTabExpanded(newExpandedState);
        trackCardExpand(newExpandedState);
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                icon={tabIcon}
                heading={heading}
                isTabExpanded={isTabExpanded}
                onShowMore={handleShowMore}
            />
            <S.GeneralInfo>
                <S.Status>
                    <Alert />
                    <Text type="caption" color="text-secondary" text={t('subscription.status.active')} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                <PriceRow period={subscriptionPeriod} subscriptionType={subscriptionType} price={price} />
            </S.GeneralInfo>
            <Text
                type="medium-text"
                bold
                color="critical-default"
                mb={4}
                text={t('subscription.text.awaitingPayment')}
            />
            <Text type="small-text" color="text-secondary" text={t('subscription.text.payYourBalance')} />
            {isTabExpanded && <CancelButton onCancel={onCancelSubscription} />}
        </SubscriptionCardWrapper>
    );
};

export default SubscriptionRedemption;
