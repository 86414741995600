import { useTranslation } from 'react-i18next';
import React from 'react';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';

import { Text } from 'components/library';

import * as S from './styled';

import { IUpcomingProductProps } from '../types';

import StatusIcon from './StatusIcon';
import PriceRow from './PriceRow';

const UpcomingProduct = ({ isTabExpanded, upcomingProduct }: IUpcomingProductProps) => {
    const { t } = useTranslation();

    const autoRenewalDate = upcomingProduct.started_from;

    return (
        <S.GeneralInfo mb={0} mt={24}>
            <S.Status>
                <StatusIcon isActive={false} />
                <Text type="caption" color="text-secondary" text={t('subscription.status.upcoming')} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <PriceRow
                price={`${getPriceFromCents(upcomingProduct?.amount)} ${upcomingProduct.currency}`}
                period={upcomingProduct.subscription_period}
                subscriptionType={t('subscription.type.subscription')}
            />
            {isTabExpanded && (
                <S.DateInfo>
                    <Text type="caption" color="text-secondary-subdued" text={t('subscription.text.autoRenewalOn')} />
                    <Text type="caption" color="text-secondary-subdued">
                        {replaceUnderscoreToDash(autoRenewalDate)}
                    </Text>
                </S.DateInfo>
            )}
        </S.GeneralInfo>
    );
};

export default UpcomingProduct;
