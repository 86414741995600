import styled, { css } from 'styled-components';

import { ReactComponent as PaymentFailedIcon } from '../img/paymentFailed.svg';

import { color } from 'theme/selectors';

const flexBlock = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SubscriptionCardHeader = styled.div<{ $isExpired?: boolean }>`
    ${flexBlock};
    margin-bottom: 24px;

    ${({ $isExpired }) =>
        $isExpired &&
        css`
            img {
                opacity: 0.5;
            }
        `}
`;

export const Name = styled.div`
    ${flexBlock};
    gap: 8px;
`;

export const ExpandButton = styled.button<{ $isTabExpanded?: boolean }>`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    transform: rotate(180deg);

    svg {
        position: relative;
        top: 2px;
    }

    ${({ $isTabExpanded }) =>
        $isTabExpanded &&
        css`
            transform: rotate(0deg);
        `}
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 0;

    svg {
        margin-right: 5px;
    }
`;

export const PriceRow = styled.div`
    ${flexBlock};
`;

export const DateInfo = styled.div`
    margin-top: 20px;
    ${flexBlock};
`;

export const GeneralInfo = styled.div<{ mb?: number; mt?: number }>`
    margin-bottom: ${({ mb = 24 }) => mb}px;
    margin-top: ${({ mt = 0 }) => mt}px;
`;

export const CancelButton = styled.button`
    margin-top: 24px;
    margin-bottom: 12px;
    cursor: pointer;
`;

export const FailedContainer = styled.div`
    margin-bottom: 20px;
    padding: 16px;
    background: ${color('surface-secondary')};
    border-radius: 12px;
`;

export const FailedIcon = styled(PaymentFailedIcon)`
    display: block;
    margin: 0 auto 7px;
`;

export const ContactLink = styled.a`
    display: block;
    margin-bottom: 16px;
    padding: 19px;
    color: ${color('text-main')};
    font-size: 17px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: ${color('surface-main')};
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
`;
