import appConfig from 'appConfig';

import { getLanguage } from 'api/utils/languageManagment';

import { DEFAULT_LANGUAGE } from 'constants/localization';

import isMobile from './isMobile';

type KeyType = keyof typeof appConfig.zendesk.ios;

export const getZendeskLink = () => {
    const language = getLanguage();

    const { zendesk } = appConfig;

    if (isMobile.iOS()) {
        return zendesk.ios[language as KeyType] || zendesk.ios[DEFAULT_LANGUAGE];
    }

    if (isMobile.android()) {
        return zendesk.android[language as KeyType] || zendesk.android[DEFAULT_LANGUAGE];
    }

    return zendesk.desktop[language as KeyType] || zendesk.desktop[DEFAULT_LANGUAGE];
};
