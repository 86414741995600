import zendesk from './zendesk';
import video from './video';
import links from './links';
import installApp from './installApp';
import base from './base';

export default {
    video,
    installApp,
    links,
    base,
    zendesk,
};
