import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { PUBLIC } from 'router/constants/routes';

import { selectGuidesInfo } from 'store/guides/selectors';
import { logout } from 'store/auth/actions';

import { sendAnalyticSideBarLogoutClick } from 'services/analytics';

import { useSidebarControl, useDispatch, useSelector } from 'hooks';

import { getPrimarySidebarLinks, getSecondarySidebarLinks } from './helpers';

import { Text } from 'components/library';
import SidebarLink from './components/SidebarLink';

import * as S from './styled';

import { linkType } from './types';

function SideBar() {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { isOpen, close } = useSidebarControl();
    const { isShowGuidesNotification } = useSelector(selectGuidesInfo);

    const navigate = useNavigate();

    const handleLogout = () => {
        sendAnalyticSideBarLogoutClick();

        dispatch(logout());

        navigate(PUBLIC.LOGIN.path);

        close();
    };

    return (
        <S.SideBarWrapper $isOpen={isOpen}>
            <S.SideBarOverlay onClick={close} />
            <S.SideBarMenu>
                {getPrimarySidebarLinks({
                    isShowGuidesNotification: Boolean(isShowGuidesNotification),
                }).map(({ ...props }, index) => (
                    <SidebarLink key={index} onClick={close} type={linkType.Primary} {...props} />
                ))}

                <S.Hr />

                {getSecondarySidebarLinks().map(({ ...props }, index) => (
                    <SidebarLink key={index} onClick={close} type={linkType.Secondary} {...props} />
                ))}

                <S.NavButton onClick={handleLogout} data-locator="burgerLogoutBtn">
                    <Text type="medium-text" color="text-secondary" text={t('sidebar.logout')} />
                </S.NavButton>

                <S.Hr />
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}

export default SideBar;
