import React from 'react';

import { CheckCircle } from 'components/Icon';

const ACTIVE_COLOR = 'success-default';
const INACTIVE_COLOR = 'border-default';

const StatusIcon = ({ isActive }: { isActive: boolean }) => (
    <CheckCircle color={isActive ? ACTIVE_COLOR : INACTIVE_COLOR} />
);

export default StatusIcon;
