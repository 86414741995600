import { Trans } from 'react-i18next';
import React from 'react';

import appConfig from 'appConfig';

import Modal from 'components/Modal';
import { Button, Text } from 'components/library';

import * as S from './styled';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    tryAgain?: () => void;
    description: React.ReactNode;
    icon: string;
}

const ErrorMessageModal = ({ isOpen, onClose, description, icon, tryAgain }: Props) => {
    const onTryAgain = () => {
        return tryAgain ? tryAgain() : window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
            <S.Wrapper data-locator="cancelSubModal">
                <S.Icon src={icon} alt="modalIcon" />
                <Text type="h5" center mb={12}>
                    <Trans i18nKey="guides.error.sorry" />
                </Text>
                <Text center mb={32} color="text-secondary">
                    {description}
                </Text>

                <Button dataLocator="ErrorMessageModal" onClick={onTryAgain}>
                    <Trans i18nKey="basics.tryAgain" />
                </Button>
                <S.ContactLink href={`mailto:${appConfig.base.supportEmail}`}>
                    <Trans i18nKey="basics.contactSupport" />
                </S.ContactLink>
            </S.Wrapper>
        </Modal>
    );
};

export default ErrorMessageModal;
