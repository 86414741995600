import { useTranslation } from 'react-i18next';
import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import StatusIcon from './StatusIcon';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';

const ExpiresSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    expirationDate,
    subscriptionType,
}: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.Status>
                <StatusIcon isActive />
                <Text type="caption" color="text-secondary" text={t('subscription.status.active')} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <PriceRow period={subscriptionPeriod} subscriptionType={subscriptionType} price={price} />

            <S.DateInfo>
                <Text type="medium-text" bold color="critical-default" text={t('subscription.text.expires')} />
                <Text type="medium-text" bold color="critical-default" text={expirationDate} />
            </S.DateInfo>
        </SubscriptionCardWrapper>
    );
};

export default ExpiresSubscription;
