import { useTranslation } from 'react-i18next';
import React from 'react';

import base from 'appConfig/base';

import { sendAnalyticResetPassContactUsClick } from 'services/analytics';

import { Colors, Text } from 'components/library';

import { Link } from './styled';

const ContactUsLink = ({ subject, color }: { subject?: string; color?: Colors }) => {
    const { t } = useTranslation();

    const subjectSuffix = subject ? `?subject=${subject}` : '';

    return (
        <Link
            onClick={sendAnalyticResetPassContactUsClick}
            href={`mailto:${base.supportEmail}${subjectSuffix}`}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="contactUsLink"
        >
            <Text type="overline" color={color} center bold text={t('basics.contactUs')} />
        </Link>
    );
};

export default ContactUsLink;
