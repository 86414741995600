import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 16px 16px;
`;

export const Icon = styled.img`
    margin: 0 auto 10px;
    max-width: 70px;
`;

export const ContactLink = styled.a`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 24px;
    margin-bottom: 16px;

    color: ${color('text-main')};
`;

export const Email = styled.strong`
    word-wrap: break-word;
`;
