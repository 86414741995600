import { Trans } from 'react-i18next';
import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

const PaymentFailedCard = () => {
    return (
        <S.FailedContainer>
            <S.FailedIcon />
            <Text type="large-text" medium mb={8} center>
                <Trans i18nKey="subscription.paymentFailed.title" />
            </Text>
            <Text mb={16} center>
                <Trans i18nKey="subscription.paymentFailed.text" />
            </Text>
            <S.ContactLink href="mailto:support@walk-fit.io?subject=Pending failed">
                <Trans i18nKey="basics.contactSupport" />
            </S.ContactLink>
            <Text center>
                <Trans i18nKey="subscription.paymentFailed.text2" />
            </Text>
        </S.FailedContainer>
    );
};

export default PaymentFailedCard;
