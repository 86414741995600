import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCountry } from 'store/user/selectors';

import {
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_CHARGEBACK,
    SUBSCRIPTION_PENDING,
    SUBSCRIPTION_REDEMPTION,
} from 'constants/payments';

import { sendAnalyticCancelSubscriptionClick } from 'services/analytics';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getUpcomingProduct, getSubscriptionIcon, getSubscriptionName, normalizeDateByLocale } from '../helpers';

import { ISubscriptionCard } from '../types';

const TRIAL_NAME = 'subscription.type.trial';
const SUBSCRIPTION_NAME = 'subscription.type.subscription';

const useSubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        product: { amount, currency, trial_amount: trialAmount },
        state,
        is_trial: isTrial,
        expired_date: expiredDate,
        cancelled_at: cancelledAt,
        product_code: productCode,
        external_id: externalId,
        period: subscriptionPeriod,
        trial_period: subscriptionTrialPeriod,
        payment_provider: paymentProvider,
        internal_cancel_at: internalCancelAt,
    } = subscription;
    const { t } = useTranslation();

    const isCancelledSubscription =
        state === SUBSCRIPTION_CANCELLED || Boolean(cancelledAt) || Boolean(internalCancelAt);
    const isSubscriptionActive = state === SUBSCRIPTION_ACTIVE;
    const isSubscriptionRedemption = state === SUBSCRIPTION_REDEMPTION;
    const isExpiredSubscription =
        (isCancelledSubscription && !isSubscriptionActive) || state === SUBSCRIPTION_CHARGEBACK;
    const isExpiresSubscription = isCancelledSubscription && isSubscriptionActive;
    const isPendingSubscription = state === SUBSCRIPTION_PENDING;

    const country = useSelector(selectCountry);

    const expirationDate = normalizeDateByLocale(expiredDate, country);
    const cancelledAtDate = normalizeDateByLocale(cancelledAt, country);

    const tabIcon = getSubscriptionIcon(productCode);
    const heading = replaceUnderscoreToDash(getSubscriptionName(productCode));
    const price = getPriceFromCents(isTrial ? trialAmount : amount);

    const trackCardExpand = (isTabExpanded: boolean) => {
        // eslint-disable-next-line no-console
        console.log('trackCardExpand', isTabExpanded);
    };

    const handleCancelSubscription = () => {
        sendAnalyticCancelSubscriptionClick();

        onCancelSubscription({
            expirationDate,
            externalId,
            productCode,
            paymentProvider,
        });
    };

    const getExpirationDate = () => {
        if (isExpiresSubscription) {
            return replaceUnderscoreToDash(expirationDate);
        }

        return replaceUnderscoreToDash(cancelledAt ? cancelledAtDate : expirationDate);
    };

    return {
        price: `${price} ${currency}`,
        state,
        tabIcon,
        heading,
        upcomingProduct: getUpcomingProduct({ subscription, country }),
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        isPendingSubscription,
        cancelledAtDate,
        trackCardExpand,
        handleCancelSubscription,
        subscriptionType: t(isTrial ? TRIAL_NAME : SUBSCRIPTION_NAME),
        expirationDate: getExpirationDate(),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
    };
};

export default useSubscriptionCard;
