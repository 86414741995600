import React from 'react';

import { setCancellationCandidate } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { SUBSCRIPTION_PENDING_FAILED } from 'constants/payments';

import { useSubscriptions, useDispatch } from 'hooks';

import Skeleton from 'components/Skeleton';
import { Box } from 'components/library';
import PaymentFailedCard from './components/SubscriptionCard/components/PaymentFailedCard';
import { SubscriptionCard, SubscriptionHelpButton } from './components';

import { CancelCandidate, ISubscription } from 'types/subscription';
import { ModalName } from 'types/modals';

const SubscriptionsTab = () => {
    const dispatch = useDispatch();

    const { isLoading, subscriptions } = useSubscriptions();

    const onCancelSubscription = (candidate: CancelCandidate) => {
        dispatch(setCancellationCandidate(candidate));

        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const displaySkeleton = isLoading && !subscriptions?.length;

    const failedFirstIndex = subscriptions.findIndex(
        (subscription) => subscription.state === SUBSCRIPTION_PENDING_FAILED
    );

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton ? (
                <Skeleton height={128} />
            ) : (
                subscriptions?.map((subscription: ISubscription, index) => {
                    if (index === failedFirstIndex) {
                        return <PaymentFailedCard key={subscription.id} />;
                    }

                    if (subscription.state !== SUBSCRIPTION_PENDING_FAILED) {
                        return (
                            <div key={subscription.id} data-locator={`subscription${index}`}>
                                <div key={index} data-locator={`subscription${index}`}>
                                    <SubscriptionCard
                                        subscription={subscription}
                                        onCancelSubscription={onCancelSubscription}
                                    />
                                </div>
                            </div>
                        );
                    }
                })
            )}
            <SubscriptionHelpButton />
        </Box>
    );
};

export default SubscriptionsTab;
