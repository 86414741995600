import React from 'react';

import useSubscriptionCard from './hooks/useSubscriptionCard';

import SubscriptionRedemption from './components/SubscriptionRedemption';
import PendingSubscription from './components/PendingSubscription';
import ExpiresSubscription from './components/ExpiresSubscription';
import ExpiredSubscription from './components/ExpiredSubscription';
import DefaultSubscription from './components/DefaultSubscription';

import { ISubscriptionCard } from './types';

const SubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        isExpiredSubscription,
        isExpiresSubscription,
        isSubscriptionRedemption,
        isPendingSubscription,
        trackCardExpand,
        handleCancelSubscription,
        state,
        upcomingProduct,
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDate,
    } = useSubscriptionCard({
        subscription,
        onCancelSubscription,
    });

    const commonProps = {
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
    };

    if (isExpiredSubscription) {
        return <ExpiredSubscription {...commonProps} expirationDate={expirationDate} />;
    }

    if (isExpiresSubscription) {
        return <ExpiresSubscription {...commonProps} expirationDate={expirationDate} />;
    }

    if (isSubscriptionRedemption) {
        return (
            <SubscriptionRedemption
                {...commonProps}
                trackCardExpand={trackCardExpand}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    if (isPendingSubscription) {
        return (
            <PendingSubscription
                {...commonProps}
                trackCardExpand={trackCardExpand}
                expirationDate={expirationDate}
                state={state}
                upcomingProduct={upcomingProduct}
            />
        );
    }

    return (
        <DefaultSubscription
            {...commonProps}
            trackCardExpand={trackCardExpand}
            expirationDate={expirationDate}
            state={state}
            upcomingProduct={upcomingProduct}
            onCancelSubscription={handleCancelSubscription}
        />
    );
};

export default SubscriptionCard;
